<template>
    <div>
        <Header v-if="!headerHidden" :data="data.Header" :back="showBackButton" />
        <div>
          <slot></slot>
        </div>
        <Footer v-if="!isFooterHidden" />
        <mobile-footer
          v-if="showMobileFooter"
          class="ipost-mobilefooter"
          :items="mobileFooterMenu"
        />
    </div>
</template>
<script>
import layoutMixin from '@/mixin/layout'

export default {
  mixins: [layoutMixin],
    props: {
      data: Object,
      isChild: Boolean,
      isModal: Boolean
    },
    components: {
      Header: () => import('./Header'),
      Footer: () => import('./Footer'),
      MobileFooter: () => import('./MobileFooter')
    },
    mounted () {
      this.init()
    },
    methods: {
      async init () {
        // await this.getCompany()
            // this.getHeaderHeight()
      }
    },
    computed: {
      showBackButton () {
        return !this.isHomeRoute
      },
      showMobileFooter () {
        if (this.$route.name === 'register' || this.$route.name === 'login') return false
        // if (this.isHomeRoute && !this.isChild && (this.isMobile)) return true
        if (this.isHomeRoute && (this.isMobile)) return true
        return false
      },
      isHomeRoute () {
        const { name } = this.$route

        const nameToCheck = [
          'home',
          'guest'
          // 'login',
          // 'register'
          // 'user.dashboard',
          // 'user.setting',
          // 'user.orders.packages',
          // 'user.orders.inprocess',
          // 'user.orders.histories'
        ]
        return nameToCheck.indexOf(name) !== -1
      },
      headerHidden () {
        if (this.$route.name === 'user.profile' && !this.isModal) return false
        return this.isHeaderHidden
      }
    }
}
</script>
