import { HEADER_HEIGHT } from '@/layouts/vaddress/Main/Header'
export default {
  methods: {
    checkPageSizeCondition (obj, defaultValue = false) {
      if (typeof obj === 'boolean') return obj

        if (typeof obj === 'object') {
          return Object.keys(obj).reduce((p, v) => {
            const func = 'is' + v.substr(0, 1).toUpperCase() + v.substr(1)
            if (this[func]) {
              p = obj[v]
            }
            return p
          }, defaultValue)
        }
        return defaultValue
    }
  },
  computed: {
    headerHeight () {
      return this.isHeaderHidden ? 0 : HEADER_HEIGHT
    },
    isHeaderHidden () {
      return this.isChild ? true : this.checkPageSizeCondition((this.data.Header || {}).Hide, false)
    },
    isFooterHidden () {
      return true
      // const hide = (this.data.Footer || {}).Hide
      // if (typeof hide === 'boolean') return hide

      // return this.isSm || this.isChild
    }
  }
}
