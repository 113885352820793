<template>
  <Page :data="pageData" :is-child="_isChild" @refresh="refresh" />
</template>
<script>

import BaseCMSPage from './BaseCMSPage'
import Page from '@/components/vaddress/cmspages/Page'

export default {
  extends: BaseCMSPage,
  components: {
    Page
  },
  computed: {
    apiPath () {
      const path = this._isParent ? this._routePath : this.$route.fullPath
      return this.prefix + (path.startsWith('/') ? path : '/' + path)
    }
  }
}
</script>

<style>

</style>
