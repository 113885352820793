<script>
export default {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const { matched } = to
      if (
        matched.findIndex(m => m.meta && m.meta.auth) !== -1 &&
        !vm.user.IsLogin) {
        // if (to.name === 'user.dashboard' || to.name === 'user.orders.packages') {
        //   vm.$router.replace({ name: 'guest' })
        //   return
        // }
        vm.$router.replace({ name: 'login' })
      }

      if (to.name === 'guest' && vm.user.IsLogin) {
        vm.$router.replace({ name: 'home' })
      }
    })
  },
  mounted () {
    if (this.initOnMounted) {
      this.init()
    }
  },
  data () {
    return {
      prefix: '',
      initOnMounted: true,
      cacheContent: true,
      initOnReturnFromChildRoute: true
    }
  },
  methods: {
      async init () {
        await this.requestCMSData()
      },
      async requestCMSData () {
        await this.$store.dispatch('webcontent/get', { path: this.apiPath, cache: this.cacheContent })
        this.$nextTick(() => {
          this.afterRequestCMSData()
        })
      },
      resetCMSData () {
        this.$store.dispatch('webcontent/remove', this.apiPath)
      },
      afterRequestCMSData () {

      },
      refresh (e) {
        this.init().finally(() => {
          if (e.done) e.done()
        })
      }
    },
    computed: {
      response () {
        return this.$store.state.webcontent.response[this.apiPath] || {}
      },
      config () {
        return this.response.Config || {}
      },
      cmsLeft () {
        return this.response.Left || {}
      },
      cmsRight () {
        return this.response.Right || {}
      },
      pageData () {
        return {
          cmsLeft: this.cmsLeft,
          cmsRight: this.cmsRight,
          config: this.config
        }
      }
      // apiPath () {
      //   const path = this._isParent ? this._routePath : this.$route.fullPath
      //   return this.prefix + (path.startsWith('/') ? path : '/' + path)
      // }
    },
    watch: {
      // $route (v, o) {
      //     const oldRoute = o.matched.find(m => m.name === o.name)
      //     const newRoute = v.matched.find(m => m.name === v.name)

      //     // If returning from children
      //     if (oldRoute.parent && oldRoute.parent.name === newRoute.name) {
      //       console.log('returning from', oldRoute.name, 'to', newRoute.name)
      //       if (this.initOnReturnFromChildRoute) this.init()
      //     }
      // }
    },
    beforeRouteLeave (to, from, next) {
      if (!this.cacheContent) {
        this.$store.dispatch('webcontent/remove', this.apiPath)
      }
      next()
    }
}
</script>
