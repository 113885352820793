<template>
<div class="flex flex-col grid grid-cols-1">
  <!-- Logo -->
  <div class="flex w-full justify-between" :class="isMobile ? 'mt-4' :''">
    <img v-if="isMobile" :src="company.Logo" style="width: 150px; height:43px;" class="mb-4" />
    <div v-if="isMobile" class="flex">
      <xlink :to="linkWA" class="bg-gray-200 border-2 h-8 rounded-md p-1" style="padding-bottom: 29px !important">
        <img src="https://elsoft.s3-ap-southeast-1.amazonaws.com/bf713ad1-33f1-11eb-a444-82afd875af05/images/a1091045-c796-4d62-9ef1-9cbdb939a007.png" width="26px" class="mt-1">
      </xlink>
      <div @click="showMenu = true">
        <b-icon
          icon="menu"
          class="border-2 bg-gray-200 rounded-md my-auto text-red-400 mr-1 ml-2 p-4">
        </b-icon>
      </div>
    </div>
  </div>
  <!-- End of Logo -->
  <!-- Header -->
  <div class="flex mb-8 md:mb-16">
    <div class="flex-1 flex-col md:flex-row">
      <h5 class="has-text-primary text-lg md:text-4xl font-bold">
      Hi {{this.user.IsLogin ? user.Name + '!' : 'iPOST'}}
      </h5>
      <p class="text-lg hidden md:block">Selamat datang di iPOST</p>
      <p class="text-lg md:hidden">Silakan pilih kebutuhan Anda</p>
    </div>
    <div v-if="!isMobile" class="items-center flex mr-2">
      <xlink :to="websiteUrl" class="flex border-2 border-red-400 p-4 rounded-lg hover:shadow-md cursor-pointer">
        <b-icon
            icon="arrow-left-circle"
            size="is-small"
            class="my-auto text-red-400 mr-1">
        </b-icon>
        <div class="font-bold text-xl text-red-400">Main Website</div>
      </xlink>
    </div>
    <div v-if="!isMobile" class="items-center flex">
      <xlink :to="linkWA" class="bg-gray-300 border-2 rounded-md p-1 pr-4 pl-4 cursor-pointer" style="padding-bottom: 13px !important">
        <img src="https://elsoft.s3-ap-southeast-1.amazonaws.com/bf713ad1-33f1-11eb-a444-82afd875af05/images/a1091045-c796-4d62-9ef1-9cbdb939a007.png" width="50px" class="mt-2">
      </xlink>
    </div>
    <div v-if="!isMobile" class="items-center w-48">
      <div class="bg-gray-300 rounded-md p-1 flex ml-4 items-center justify-center">
        <mascot-avatar width="60px" height="60px" class="mr-2"></mascot-avatar>
        <div class="flex flex-col">
          <div class="font-bold text-xl">Account</div>
            <b-button size="is-small" type="is-info" class="text-xl" @click="signOut">Sign out</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Header -->
  <!-- Content -->
  <div class="flex-1">
    <div :class="isMobile ? 'mb-12' : 'flex mb-14 gap-5 grid gap-0 grid-cols-2'">
        <live-tracking-card id="live_tracking" id2="live_tracking" :dataTracking="dataTracking" class="bg-gray-100 col-span-2" :class="isMobile ? 'mb-4' : ''"></live-tracking-card>
        <calculator-card id="cost_calculator_card" id2="cost_calculator_card" class="bg-gray-100 col-span-2" :class="isMobile ? 'mb-4' : ''"></calculator-card>
        <mascot-card
        :img="jastipMascotImg"
        id="dashboard_jastip_card"
        title="JASTIP"
        class="bg-gray-100 col-span-2"
        :class="isMobile ? 'mb-4' : ''"
        :left-offset="-3.6"
        :mobile-left-offset="-4.9"
      >
        <p :class="isMobile ? 'mb-2' : ''" class="text-xs md:text-base pr-4">Ribet belanja sendiri? Serahkan saja kepada kami</p>
        <b-button type="is-warning-gradient" style="padding-right: 20px; padding-left: 20px; height: 32px;" @click="showOrderModal">ORDER</b-button>
        </mascot-card>
        <mascot-card
          :img="topupMascotImg"
          id="dashboard_jastip_card"
          title="TOP UP"
          class="bg-gray-100 mb-4 col-span-2"
          :left-offset="-3.6"
          :mobile-left-offset="-4.9"
        >
          <p :class="isMobile ? 'mb-2' : ''" class="text-xs md:text-base pr-4">Silahkan Topup Disini</p>
          <b-button type="is-warning-gradient" style="padding-right: 20px; padding-left: 20px; height: 32px;" @click="showTopup">TOP UP</b-button>
        </mascot-card>
        <mascot-card
            id="dashboard_warehouse_card"
            class="bg-gray-100"
            :img="warehouseMascotImg"
            title="Pengiriman Luar Negeri"
        >
          <p class="text-xs md:text-base">Dapatkan alamat gudang kami disini</p>
          <div :class="warehouses.International.length < 3 ? 'flex relative'  :'flex w-full justify-center relative'">
              <div class="mt-2 mr-1 relative" :style="isMobile ? 'width: 312px !important;' : 'max-width: 382px; !important;'">
                <div v-show="condSlideLeft1 && warehouses.International.length > 3" @click="slideAction('left', 'scrollA')" class="absolute mt-7" style="left: -24px;'">
                  <b-icon
                    icon="chevron-left"
                    size="is-medium"
                    class="my-auto text-red-400">
                  </b-icon>
                </div>
                <div id="scrollA" v-on:scroll.passive="handleScroll($event, 'scrollA')" class="flex overflow-x-scroll" style="scrollAar-width: none;">
                  <div v-for="warehouse in warehouses.International" :key="warehouse.Oid" :class="isMobile ? '' : 'pl-4 pr-4'" class="cursor-pointer border-2 rounded-2xl" style="margin-left: 4px;" @click="showAddressDetail(warehouse)">
                    <div :class="[ (isMobile && warehouses.International.length < 3) ? 'ml-2' : '' ]" class="w-full flex justify-center mt-2">
                      <img :src="warehouse.Icon" :class="[ (isMobile && warehouses.International.length < 3) ? 'ml-4 mr-2' : 'mr-0', { 'mr-16 ml-12 rounded-full' : isMobile, 'md:mr-8 rounded-full' : !isMobile }]" :style="isMobile ? 'width: 40px; height: 40px; margin-right: 8px !important;' : 'width: 50px; height: 50px;'" />
                      <div v-if="isMobile" :class="warehouses.International.length < 3 ? 'mr-5' : 'mr-10'"></div>
                    </div>
                    <div :class="isMobile ? 'pl-2' : 'pl-8 pr-8'" class="text-center mb:text-xl font-bold text-sm pt-2 mr-1">{{ warehouse.Text }}</div>
                  </div>
                </div>
                <div v-show="condSlideRight1 && warehouses.International.length > 3"  @click="slideAction('next', 'scrollA')" class="absolute mt-7" style="right: -28px; top: 0;'">
                  <b-icon
                    icon="chevron-right"
                    size="is-medium"
                    class="my-auto text-red-400">
                  </b-icon>
                </div>
              </div>
            </div>
        </mascot-card>
        <mascot-card
            id="dashboard_warehouse_card"
            :class="isMobile ? 'bg-gray-100 mt-12' : 'bg-gray-100'"
            :img="warehouseDomesticMascotImg"
            title="Pengiriman Dalam Negeri"
        >
            <p class="text-xs md:text-base">Pengiriman domestik hanya berlaku dalam CBM</p>
            <div :class="warehouses.Domestic.length < 3 ? 'flex relative'  :'flex w-full justify-center relative'">
              <div class="mt-2 mr-1 relative" :style="isMobile ? 'width: 312px !important;' : 'max-width: 382px; !important;'">
                <div v-show="condSlideLeft2 && warehouses.Domestic.length > 3" @click="slideAction('left', 'scrollB')" class="absolute mt-7" style="left: -24px;'">
                  <b-icon
                    icon="chevron-left"
                    size="is-medium"
                    class="my-auto text-red-400">
                  </b-icon>
                </div>
                <div id="scrollB" v-on:scroll.passive="handleScroll($event, 'scrollB')" class="flex overflow-x-scroll" style="scrollbar-width: none;">
                  <div v-for="warehouse in warehouses.Domestic" :key="warehouse.Oid" :class="isMobile ? '' : 'pl-4 pr-4'" class="cursor-pointer border-2 rounded-2xl" style="margin-left: 4px;" @click="showAddressDetail(warehouse)">
                    <div :class="[ (isMobile && warehouses.Domestic.length < 3) ? 'ml-2' : '' ]" class="w-full flex justify-center mt-2">
                      <img :src="warehouse.Icon" :class="[ (isMobile && warehouses.Domestic.length < 3) ? 'ml-4 mr-2' : 'mr-0', { 'mr-16 ml-12 rounded-full' : isMobile, 'md:mr-8 rounded-full' : !isMobile }]" :style="isMobile ? 'width: 40px; height: 40px; margin-right: 8px !important;' : 'width: 50px; height: 50px;'" />
                      <div v-if="isMobile" :class="warehouses.Domestic.length < 3 ? 'mr-5' : 'mr-10'"></div>
                    </div>
                    <div :class="isMobile ? 'pl-2' : 'pl-8 pr-8'" class="text-center mb:text-xl font-bold text-sm pt-2 mr-1">{{ warehouse.Text }}</div>
                  </div>
                </div>
                <div v-show="condSlideRight2 && warehouses.Domestic.length > 3"  @click="slideAction('next', 'scrollB')" class="absolute mt-7" style="right: -28px; top: 0;'">
                  <b-icon
                    icon="chevron-right"
                    size="is-medium"
                    class="my-auto text-red-400">
                  </b-icon>
                </div>
              </div>
            </div>
        </mascot-card>
    </div>
    <!-- Mobile tour card -->
    <!-- <div :class="isMobile ? 'grid gap-0 grid-cols-1 mb-4' : 'grid gap-5 grid-cols-4 mb-4'">
    </div> -->
    <!-- End of mobile tour card -->
    <!-- <p class="text-lg mt-4 mb-2">Tutorial menggunakan iPOST</p> -->
    <div :class="['grid gap-4 auto-rows-fr mb-8', {
      'grid-cols-5 mb-4': this.isMobile,
      'grid-cols-10 gap-5 mt-0': !this.isMobile
    }]">
      <div class="col-span-5">
        <video-card
          :thumb="videoThumb"
          :video="videoUrl">
        </video-card>
      </div>
      <contact-card class="bg-gray-100 col-span-5" v-if="!isMobile"></contact-card>
    </div>
    <contact-card v-if="isMobile" class="bg-gray-100 mb-4"></contact-card>
  </div>
  <!-- End of Content -->

  <!-- Modals -->
  <warehouse-modal v-bind="modalProps('addressdetail')" @close="closeModal('addressdetail')"></warehouse-modal>
  <!-- End of Modals -->

  <b-modal v-model="topUpModal" :width="300">
      <div class="card">
        <div class="card-content">
          <p class="text-center mb-4">Top up</p>
          <div class="flex justify-center">
            <router-link :to="{ path: '/proxy', query: { initial_form_type: 'form' } }">
              <b-button type="is-primary" class="mr-4">+Form</b-button>
            </router-link>
          </div>
        </div>
      </div>
  </b-modal>
  <b-modal v-model="orderModal" :width="300">
      <div class="card">
        <div class="card-content">
          <p class="text-center mb-4">Tambahkan list detail barang anda</p>
          <div class="flex justify-center">
            <router-link :to="{ path: '/proxy', query: { initial_form_type: 'form' } }">
              <b-button type="is-primary" class="mr-4">+Form</b-button>
            </router-link>
            <router-link :to="{ path: '/proxy', query: { initial_form_type: 'link' } }">
              <b-button type="is-primary">+Link</b-button>
            </router-link>
          </div>
        </div>
      </div>
  </b-modal>
  <b-modal v-model="showMenu" :width="640" class="aw">
    <div class="p-4 card" style="position: fixed !important; top: 0px; background-color: transparent;">
      <div class="pt-16" @click="showMenu = false"></div>
      <div class="p-4 boder-2 rounded-lg mr-1" style="background-color: #403939;">
        <div class="items-center w-36">
          <div class="bg-gray-100 rounded-md p-1 flex ml-4 items-center justify-center">
            <mascot-avatar width="60px" height="60px"></mascot-avatar>
            <div class="flex flex-col">
              <div class="font-bold text-xs">Account</div>
                <div class="border-2 rounded-md text-xs p-1 pr-2 pl-2 text-white" style="background-color: #23B6CD;" @click="signOut">Sign out</div>
            </div>
          </div>
        </div>
        <router-link to="/user/orders">
          <div class="flex ml-4 mt-4 text-white">
            <!-- <b-icon
              icon="package-variant-closed"
              class="my-auto text-white mr-2">
            </b-icon> -->
            <img src="https://elsoft.s3-ap-southeast-1.amazonaws.com/bf713ad1-33f1-11eb-a444-82afd875af05/images/16dab9dd-44eb-4677-a818-14f682c7b87e.png" :style="isMobile ? 'height:27px;width:27px;' : 'width:8%;'" class="mr-2">
            <p>Lacak paket</p>
          </div>
        </router-link>
        <router-link to="/user/setting">
          <div class="flex ml-4 mt-4 text-white">
            <!-- <b-icon
              icon="cog-outline"
              class="my-auto text-white mr-2">
            </b-icon> -->
            <img src="https://elsoft.s3-ap-southeast-1.amazonaws.com/bf713ad1-33f1-11eb-a444-82afd875af05/images/04b33a49-950a-41a2-a3d3-80386ad512e1.png" :style="isMobile ? 'height:27px;width:27px;' : 'width:8%;'" class="mr-2">
            <p>Pengaturan</p>
          </div>
        </router-link>
        <xlink :to="websiteUrl">
          <div class="flex ml-4 mt-4 text-white">
            <!-- <b-icon
              icon="web"
              class="my-auto text-white mr-2">
            </b-icon> -->
            <img src="https://elsoft.s3-ap-southeast-1.amazonaws.com/bf713ad1-33f1-11eb-a444-82afd875af05/images/0be4024a-dc19-4b0d-9083-ee4b981ad5a8.png" :style="isMobile ? 'height:27px;width:27px;' : 'width:8%;'" class="mr-2">
            <p>Main Website</p>
          </div>
        </xlink>
        <xlink :to="linkWA" class="flex ml-4 mt-4 text-white">
          <!-- <b-icon
            icon="whatsapp"
            class="my-auto text-white mr-2">
          </b-icon> -->
          <img src="https://elsoft.s3-ap-southeast-1.amazonaws.com/bf713ad1-33f1-11eb-a444-82afd875af05/images/f8c6026b-6b0a-4b3c-8575-c22af6b4479d.png" :style="isMobile ? 'height:27px;width:27px;' : 'width:8%;'" class="mr-2">
          <p>WhatsApp</p>
        </xlink>
        <tour-card v-if="isMobile" @click="showMenu = false, $refs.mobileTour.start()" class="mt-16"></tour-card>
      </div>
    </div>
  </b-modal>
  <tour v-if="isMobile" :scenes="mobileTourScenes" ref="mobileTour" storage-key="tour"></tour>
</div>
</template>

<script>
import CMSPage from '../../base/CMSPage'
const mobileTourScenes = [{
  elementId: 'dashboard_warehouse_card',
  anchor: 'bottom'
}, {
  elementId: 'dashboard_jastip_card',
  anchor: 'bottom'
}, {
  elementId: 'cost_calculator_card',
  anchor: 'top'
}, {
  elementId: 'ipost-mobilefooter',
  anchor: 'top'
}, {
  elementId: 'ipost-mobilefooter',
  anchor: 'top'
}, {
  elementId: 'ipost-mobilefooter',
  anchor: 'top'
}]

export default {
  extends: CMSPage,
  components: {
    MascotCard: () => import('./components/MascotCard'),
    VideoCard: () => import('./components/VideoCard'),
    CalculatorCard: () => import('./components/CalculatorCard'),
    LiveTrackingCard: () => import('./components/LiveTrackingCard'),
    ContactCard: () => import('./components/ContactCard'),
    TourCard: () => import('./components/TourCard'),
    WarehouseModal: () => import('./WarehouseModal'),
    MascotAvatar: () => import('@/components/vaddress/MascotAvatar'),
    Xlink: () => import('@/components/link/Link'),
    Tour: () => import('@/components/vaddress/Tour')
  },
  data () {
    return {
      dataTracking: null,
      orderModal: false,
      topUpModal: false,
      showMenu: false,
      countScrollB: 0,
      countScrollC: 0,
      condSlideLeft1: false,
      condSlideRight1: true,
      condSlideLeft2: false,
      condSlideRight2: true,
      wb: null,
      sw: null,
      sl: null,
      count: null,
      linkWA: 'https://api.whatsapp.com/send/?phone=628117710880&text=Selamat+Datang+di+iPOST&type=phone_number&app_absent=0'
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    document.title = 'iPOST - Jasa Kirim Barang Luar Negri - Indonesia'
    const dashboardTour = localStorage.getItem('tour1')
    if (!dashboardTour) this.startTour()
    this.liveTracking()
  },
  methods: {
    showTopup () {
      if (this.user.IsLogin) {
        this.$router.push('/topup')
      } else {
      this.$router.push('/login')
      }
    },
    async liveTracking () {
       this.dataTracking = await this.$store.dispatch('webcontent/get', { path: '/livetracking', cache: true })
    },
    handleScroll (event, value) {
        var a = document.getElementById(value)
        // var widthBound = a.getBoundingClientRect().width - 0.171875
        var wb = a.getBoundingClientRect().width
        var sw = a.scrollWidth
        var sl = a.scrollLeft
        var count = sw - sl
        var rest = count - wb
        this.sl = sl
        // var rest2 = sw - sl
        // var op6 = 319.8222351074219
        // this.count(count, count - rest)
        // console.log('12', sw, sl, wb, count, rest)

        if (value === 'scrollA') {
          if (sw === count) {
            this.condSlideLeft1 = false
            this.condSlideRight1 = true
          } else if ((rest + sl - 50) < sl) {
            this.condSlideLeft1 = true
            this.condSlideRight1 = false
            this.countScrollB = 0
          }
        } else if (value === 'scrollB') {
          if (sw === count) {
            this.condSlideLeft2 = false
            this.condSlideRight2 = true
          } else if (rest < sl) {
            this.condSlideLeft2 = true
            this.condSlideRight2 = false
            this.countScrollB = 0
          }
        }
    },
    slideAction (e, value) {
      var a = document.getElementById(value)
      if (e === 'next') {
        this.countScrollB++
        this.countScrollC = this.countScrollB * 60
        a.scrollTo(this.countScrollC, 20)
      } else {
        this.countScrollB--
        this.countScrollC = this.sl
        this.countScrollC = this.countScrollC - 60
        if (this.countScrollC < 0) this.countScrollC = 0
        if (this.countScrollB < 0) this.countScrollB = 0
        a.scrollTo(this.countScrollC, 20)
      }
    },
    async connectChat () {
      const loading = this.$buefy.loading.open()
      try {
        window.open(this.LiveChat)
        // this.$store.dispatch('socket/getHistory')
        // this.$router.push('/user/chat')
      } catch (err) {
      } finally {
        loading.close()
      }
    },
    showAddressDetail (d) {
      if (this.user.IsLogin) {
        this.openModal('addressdetail', {
          url: `/user/dashboard/address?WarehouseId=${d.Oid}`
        })
      } else {
        this.$router.push('/login')
      }
    },
  showOrderModal () {
    if (this.user.IsLogin) {
      this.orderModal = true
    } else {
      this.$router.push('/login')
    }
  },
  signOut () {
    this.$buefy.dialog.confirm({
          message: 'Apakah anda yakin untuk keluar?',
          type: 'is-primary-gradient',
          cancelText: 'Batal',
          confirmText: 'Ok',
          onConfirm: async () => {
            this.logout()
          }
      })
  },
  startTour () {

  }
  },
  computed: {
    LiveChat () {
      return this.response.LiveChat
    },
    videoUrl () {
      return this.response.VideoURL
    },
    videoThumb () {
      return this.response.VideoThumb
    },
    warehouses () {
      return this.response.Warehouses
    },
    websiteUrl () {
      return this.response.WebsiteUrl
    },
    mobileTourScenes () {
      const { MobileTours } = this.company
      return mobileTourScenes.map((scene, index) => ({
        ...scene,
        img: MobileTours[index]
      }))
    },
    warehouseMascotImg () {
      return this.company.MascotImages.Warehouse
    },
    warehouseDomesticMascotImg () {
      return this.company.MascotImages.WarehouseDomestic
    },
    topupMascotImg () {
      return this.company.MascotImages.Topup
    },
    jastipMascotImg () {
      return this.company.MascotImages.Jastip
    }
  }
}
</script>

<style>
    ::-webkit-scrollbar {
        display: none;
    }
    .aw .modal-background{
      background-color: #6b6b6e;
      opacity: 0.8 !important;
    }
</style>
