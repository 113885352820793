<template>
  <layout :data="layoutData" :is-child="isChild" :is-modal="isModal">
    <div :class="['w-full transition', pageClasses]" :style="pageStyles">
      <pull-to-refresh v-if="enablePullToRefresh" @refresh="handlePullToRefresh"></pull-to-refresh>
      <div class="flex w-full flex-col md:flex-row" :style="containerStyles">
          <slot>
            <slot name="beforecmsleft"></slot>
            <slot name="cmsleft">
              <div v-if="cmsLeft && cmsLeft.Data" :id="cmsLeft.Id" :class="[`w-${cmsLeft.Column || 'full md:w-1/2'} ${cmsLeft.Margin || 'mx-auto'}`, {
                'md:mr-4': cmsRight && cmsRight.Data && (cmsLeft.Column && cmsLeft.Column.indexOf('w-') !== -1 || cmsRight.Column && cmsRight.Column.indexOf('w-') !== -1)
              }]">
              <div :class="cmsLeft.Class">
                <slot name="beforecmsleftcomponents"></slot>
                <slot name="cmsleftcomponents">
                  <div
                    v-for="(d, i) in cmsLeft.Data"
                    :key="i"
                    :class="getContentClasses(d, i, cmsLeft)"
                    :style="getContentStyles(d, i)"
                  >
                    <slot name="cmsleftcomponent" v-bind="{ data: d, i }">
                      <slot :name="makeSlotName('cmslefttype', d.Type)" v-bind="{ data: d, i }" >
                        <div v-if="d.Id">
                          <slot :name="makeSlotName('cmsleftid', d.Id)" v-bind="{ data: d, i }">
                            <cms :data="[d]"></cms>
                          </slot>
                        </div>
                        <cms v-else :data="[d]"></cms>
                      </slot>
                    </slot>
                  </div>
                </slot>
                <slot name="aftercmsleftcomponents"></slot>
              </div>
              </div>
            </slot>
            <slot name="aftercmsleft"></slot>
            <slot name="cmsRight">
              <div v-if="cmsRight && cmsRight.Data" :id="cmsRight.Id" :class="[`w-${cmsRight.Column} mx-auto`]">
                <div :class="cmsRight.Class">
                <div
                  v-for="(d, i) in cmsRight.Data"
                  :key="i"
                  :class="getContentClasses(d, i, cmsRight)"
                  :style="getContentStyles(d, i)"
                >
                  <slot :name="makeSlotName('cmsrighttype', d.Type)" v-bind="{ data: d, i }">
                    <div v-if="d.Id">
                      <slot :name="makeSlotName('cmsrightid', d.Id)" v-bind="{ data: d, i }">
                        <cms :data="[d]"></cms>
                      </slot>
                    </div>
                    <cms v-else :data="[d]"></cms>
                  </slot>
                </div>
                </div>
                <slot name="aftercmsrightcomponents"></slot>
              </div>
            </slot>
          </slot>
          <slot name="aftercms"></slot>
      </div>
      </div>
  </layout>
</template>

<script>

/**
 * image = 12
 * header = 2 lg:5
 * page = 2 lg:4
 *
 * margin = image + header + page
 */

import Layout from '@/layouts/vaddress/Main'
import layoutMixin from '@/mixin/layout'

export default {
  mixins: [layoutMixin],
  components: {
      Layout,
      cms: () => import('@/components/cms'),
      PullToRefresh: () => import('../common/PullToRefreshIndicator')
  },
  props: {
    data: {
      type: Object,
      default: () => ({ cmsLeft: {}, cmsRight: {}, config: {} })
    },
    isChild: Boolean,
    isModal: Boolean
  },
  data () {
    return {
      pullRefreshing: false
    }
  },
  methods: {
    handlePullToRefresh (e) {
      console.log('pull refresh')
      this.pullRefreshing = true
      this.$emit('refresh', {
        done: () => {
          this.pullRefreshing = false
          e.done()
        }
      })
    },
    getContentClasses (d, i, config = {}) {
      const { _Page = {} } = d
      const { Padding = true } = _Page
      const { MarginPerItem = this.$route.path.includes('/dashboard') ? 0 : 4 } = config
      return {
        '-mx-4 -my-4': !Padding,
        [`mb-${MarginPerItem}`]: true
      }
    },
    getContentStyles (d, i) {
      const { _Page = {} } = d
      const { Padding = true } = _Page
      if (i === 0 && !Padding && !this.isChild) {
        return {
          'margin-top': 'calc(-' + this.headerHeight + ' - 1rem)'
        }
      }
    },
    makeSlotName (prefix, value) {
      return `${prefix}(${value.replace(/\./g, '-')})`
    }
  },
  computed: {
    cmsLeft () {
      return this.data.cmsLeft || {}
    },
    cmsRight () {
      return this.data.cmsRight || {}
    },
    config () {
      return this.data.config || {}
    },
    pageClasses () {
      const { Page = {} } = this.config
      const { Padding } = Page

      const paddingClasses = 'px-4 py-4'

      const classes = {}

      if (this.isChild) classes['is-child'] = true

      if (typeof Padding === 'object') {
        classes[paddingClasses] = this.checkPageSizeCondition(Padding, true)
      } else {
        classes[paddingClasses] = (!this.isChild && typeof Padding === 'undefined') || Padding
      }
      return classes
    },
    containerStyles () {
      const styles = this.pageStyles
      return {
        'min-height': styles['min-height']
      }
    },
    pageStyles () {
      let styles = {}
      if (!this.isChild) {
        styles = {
          ...styles,
          'margin-top': this.headerHeight,
          'min-height': this.isSm ? `calc(100vh - ${this.headerHeight})` : `calc(100vh - ${this.headerHeight})`
        }
      }
      if (this.isSm) {
        styles = {
          ...styles
        // transform: this.pullRefreshing || !this.enablePullToRefresh ? 'translateY(0)' : 'translateY(-100px)'
        }
      }
      return styles
    },
    layoutData () {
      return {
        Header: {
          ...this.config.Header
        },
        Footer: {
          ...this.config.Footer
      }
      }
    },
    enablePullToRefresh () {
      return this.isSm && this.config.PullToRefresh
    }
  }
}
</script>

<style>

</style>
