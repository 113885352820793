<template>
    <div :class="headerClasses" :style="headerStyles">
        <div v-if="back" class="pl-2 flex items-center cursor-pointer" @click="backAction(), setField()">
          <b-icon
              icon="menu-left"
              size="is-large">
          </b-icon>
        </div>
        <div class="flex items-center">
          <mascot-avatar class="mr-4" v-if="data.ShowMascot"></mascot-avatar>
          <h1 v-if="data.Title" class="text-2xl font-bold">{{data.Title}}</h1>
        </div>
        <!-- <router-link v-else to="" :style="itemStyles" class="px-4 flex items-center">
          <img
            :src="logo"
            class="object-contain"
            :style="logoStyles"
             @click="window ? false : openWindow ()"  alt=""
          />
        </router-link> -->

        <!-- Web menu -->
        <ul class="flex flex-1" v-if="!hideMenu">
            <li class="hidden md:block" v-for="(menu, i) in header.Menu" :key="i">
              <x-link :class="linkClasses" :style="itemStyles" :to="menu.Url || menu.To || '/'">
                {{menu.Title}}
              </x-link>
            </li>
        </ul>

        <ul class="flex hidden md:block" v-if="!hideMenu">
          <li>
            <router-link v-if="!user.IsLogin" :class="linkClasses" :style="itemStyles" to="/login">
              Login
            </router-link>
            <b-dropdown :triggers="['hover']" v-else aria-role="menu" position="is-bottom-left">
              <div :style="itemStyles" slot="trigger" class="flex items-center justify-center">
                <button :class="['', linkClasses]">
                  <span>{{user.Name}}</span>
                </button>
              </div>
              <b-dropdown-item v-for="(m, i) in header.UserMenu" :key="i" has-link aria-role="menuitem">
                <router-link :to="m.Url || m.To || '/'">{{m.Title}}</router-link>
              </b-dropdown-item>
            </b-dropdown>
          </li>
        </ul>
        <!-- End of web menu -->
    </div>
</template>

<script>
export const HEADER_HEIGHT = '84px'
export const HEADER_WIDTH = '100%'

export default {
  components: {
    XLink: () => import('@/components/link/Link'),
    MascotAvatar: () => import('@/components/vaddress/MascotAvatar')
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    back: Boolean
  },
  created () {
    document.addEventListener('scroll', this.windowScroll)
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.windowScroll, false)
  },
  data () {
    return {
      scroll: 0,
      window: window.innerWidth < 767
    }
  },
  methods: {
    backAction () {
      if (this.$route.query && this.$route.query.topup) this.$router.back()
      if (this.$route.path.includes('user/orders') || this.$route.path.includes('user/checkout')) this.$router.replace('/user/orders')
      else this.$router.back()
    },
    setField () {
      this.$store.commit('service/SET_CONDITION_NOTIF', false)
    },
    windowScroll () {
      this.scroll = window.scrollY
    }
  },
  computed: {
    header () {
      var company = this.$store.state.service.company
      return company.Header
    },
    logo () {
      return this.company.Logo
    },
    hideMenu () {
      return true
      // return this.data.HideMenu || this.isMobile
    },
    headerClasses () {
      const { Translucent = false } = this.data
      if (!this.window && this.$route.path === '/user/profile') {
        return [
          'header fixed w-3/4 shadow top-0 right-0 z-10 flex',
        {
          'bg-white text-gray-700': !Translucent || this.scroll > 100,
          'bg-transparent text-white': Translucent && this.scroll < 100
        }
      ]
      } else {
          return [
            'header fixed w-full shadow top-0 left-0 z-10 flex',
          {
            'bg-white text-gray-700': !Translucent || this.scroll > 100,
            'bg-transparent text-white': Translucent && this.scroll < 100
          }
        ]
      }
    },
    linkClasses () {
      const { Translucent = false } = this.data
      return [
        'block px-4 flex items-center',
        {
          'text-white hover:text-gray-500': Translucent && this.scroll < 100
        },
        {
          'text-gray-700 hover:text-gray-900': !Translucent || this.scroll >= 100
        }
      ]
    },
    headerStyles () {
      return {
        height: HEADER_HEIGHT,
        width: HEADER_WIDTH
      }
    },
    logoStyles () {
      return {
        height: `calc(${HEADER_HEIGHT} * .75)`
      }
    },
    itemStyles () {
      return {
        height: HEADER_HEIGHT
      }
    }
  }
}
</script>
